import Head from 'next/head';
import dynamic from 'next/dynamic';
import NextJsImage from 'next/image';
import { useRouter } from 'next/router';

import { H4, LogoHeader, Paragraph } from '#styleguide/index';

const Login = dynamic(() => import('../layout/login-register/login'), {
  // eslint-disable-next-line react/display-name
  loading: () => <p>Loading</p>
});

const LoginUser = () => {
  const router = useRouter();

  const onClickRecovery = () => router.push('/recovery-password');

  return (
    <>
      <Head>
        <title>Iniciar sesión | Academia online de programación y tecnología</title>
        <meta name="description" content="Iniciar sesión en Crack The Code" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <section className="login_tile">
        <LogoHeader />
        <div className="form_tile">
          <div className="container-image">
            <NextJsImage
              className="image_login"
              src="/img_login.webp"
              alt="imagen login"
              width={504}
              height={620}
            />
          </div>
          <div className="login-form-container">
            <div className="form_card-container">
              <H4>¡Hola de nuevo!</H4>
              <Paragraph size="21">
                Ingresa para ir a la Zona de Padres o a la Zona de Estudiantes de cada uno
                de tus pequeños:
              </Paragraph>
              <Login onClickRecovery={onClickRecovery} />
            </div>
          </div>
        </div>
        <div className="bg_deg"></div>
      </section>
    </>
  );
};

export default LoginUser;
